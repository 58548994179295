<template>
    <div>
      <b-row>
        <b-col lg="6" md="12">
          <b-form-group label="Nombre" description="Nombre del conteo físico">
            <b-form-input v-model="group.name" type="text" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="12">
          <b-form-group label="Fecha" description="Fecha del conteo">
            <b-form-datepicker v-model="group.count_date" required></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="12">
          <b-form-group label="Ubicación" description="Ubicación del conteo">
            <vu-select v-model="group.location_id" :options="locations" :reduce="x => x.id" label="complete_name" required></vu-select>
          </b-form-group>
        </b-col>
        <b-col lg="12" md="12">
          <b-form-group label="Notas" description="Notas adicionales">
            <b-form-textarea v-model="group.notes"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-left">
          <b-button variant="success" @click="createGroup" class="mr-2">Crear</b-button>
          <b-button variant="danger" @click="$bvModal.hide('modal-newGroup')">Cancelar</b-button>
        </b-col>
      </b-row>
    </div>
   </template>
   
   <script>
   import axios from 'axios';
   
   export default {
    name: 'CreateStockAdjustment',
    data() {
      return {
        locations: [],
        sucursales: [],
        group: {
          name: '',
          count_date: new Date().toISOString().split('T')[0],
          location_id: null,
          sucursal_id: null,
          notes: ''
        }
      };
    },
    mounted() {
      this.getLocations();
    },
    methods: {
      getLocations() {
        axios.get('/stocklocations')
          .then(response => {
            this.locations = response.data.filter(n=>n.type_usage=="internal");
          })
          .catch(error => {
            this.$emit('error', error.response);
          });
      },
      
      createGroup() {
        axios.post('/stock/physicalcount/group', this.group)
          .then(response => {
            this.$emit('created', response.data.id);
            this.$bvModal.hide('modal-newGroup');
          })
          .catch(error => {
            this.$emit('error', error.response);
          });
      }
    }
   };
   </script>