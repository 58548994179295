<template>
  <div>
    <Nav> </Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h1>Reporte de Articulos / Popularidad</h1>
        </b-col>
      </b-row>
      <ReportSearchBar @search="searchRecv"></ReportSearchBar>
      <b-row>
        <b-col>
          <download-csv :data="tablePopularidad.data" class="btn btn-primary"
            :name="'popularidad' + initialDate + 'to' + lastDate + '.csv'" separator-excel>Descargar
          </download-csv>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card no-body class="p-3">
            <b-row>
              <b-col>
                <h3>Popularidad</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Buscar:">
                  <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                
                <b-table small :per-page="byPage" :filter="search" :current-page="page" responsive striped sort-by="quantity"
                  :sort-desc="true" hover :fields="tablePopularidad.headers" :items="tablePopularidad.data"></b-table>
                <b-row>
                  <b-col>
                    <b-pagination v-model="page" :total-rows="tablePopularidad.data.length" :per-page="byPage"
                      aria-controls="my-table"></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "../../components/Nav.vue";
import moment from 'moment';
import ReportSearchBar from "./searchBar/ReportSearchBar.vue";


export default {
  name: "Popularidad",
  components: { Nav, ReportSearchBar },
  watch: {
    initialDate(i) {
      this.minLastDate = moment(i).add(1, 'days').format()
      if (moment(this.initialDate).isAfter(moment(this.lastDate))) {
        this.lastDate = null
      }
    }
  },
  data() {
    return {
      search: "",
      byPage: 30,
      page: 1,
      allSucsArray: [],
      comensales: 0,
      ventasSum: 0,
      ticketsSum: 0,
      avgTicket: 0,
      avgTicketPp: 0,
      avgcomensales: 0,
      tableSales: {
        data: [],
        headers: [
          { key: "fecha", label: "Fecha", sortable: true },
          { key: "total", label: "Total", sortable: true }
        ]
      },
      tablePopularidad: {
        data: [],
        headers: [
          { key: "id", label: "ID" },
          { key: "clave", label: "Clave" },
          { key: "ndescription", label: "Nombre", sortable: true },
          { key: "restaurant_area_id", label: "Id Area", sortable: true },
          { key: "restaurant_area_name", label: "Area" },
          { key: "group", label: "Id Grupo", sortable: true },
          { key: "group_name", label: "Grupo" },
          { key: "product_price", label: "Precio" },
          { key: "quantity", label: "Cantidad", sortable: true },
          { key: "list_price", label: "Precio Lista" },
          { key: "total_without_discount", label: "Tot. Sin desc" },
          { key: "total_with_discount", label: "Tot. con desc" },
        ]
      },
      salesReport: [],
      salesChart: {
        chart_title: "Ventas",
        chartData: [{
          name: moment().add(1, "days").format(),
          totals: 30,
          totals2: 50,
        }, {
          name: moment().add(2, "days").format(),
          totals: 40,
          totals2: 50,
        }, {
          name: moment().add(3, "days").format(),
          totals: 70,
          totals2: 50,
        }],
        config: {
          key: "date",
          values: ["total"],
          tooltip: { label: "total" },
          labelRotation: 45,
          color: {
            default: '#222f3e',
            current: '#41B882'
          }
        },
      },
      initialDate: null,
      lastDate: null,
      minLastDate: null
    };
  },
  mounted() {
    moment().locale("es-mx")
    this.initialDate = moment().subtract(7, "days").format()
    this.lastDate = moment().add(1, "days").format()
    this.minLastDate = moment(this.initialDate).add(1, 'days').format()
  },
  methods: {
    getPopularidad() {
      let that = this
      let queryparam = { initial_date: moment(that.initialDate).format("YYYY-MM-DD"), end_date: moment(that.lastDate).format("YYYY-MM-DD"), sucursales: this.sucursales }
      axios
        .post("/report/popularidad", queryparam)
        .then(function (response) {
          that.tablePopularidad.data = response.data
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("Error", err.response.data, "danger");
        })
    },
    searchRecv(p) {
      console.log(p)
      this.initialDate = p.initDate
      this.lastDate = p.endDate
      this.sucursales = p.sucursales
      this.changeDate()
    },
    changeDate() {
      this.getPopularidad()
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  }
}
</script>