import Vue from 'vue'
import Vuex from 'vuex'
import api from './modules/api'
import server from './modules/server'
import websocket from './modules/websocketsio'
import auth from './modules/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    websocket,
    server,
    auth,
  }
})
