<template>
<div>
    <Nav></Nav>
    <b-container fluid>
    <b-row>
        <b-col class="text-left">
        <h1>Conteo Físico #{{ actualGroup.id }}</h1>
        </b-col>
    </b-row>
    <b-row class="text-left">
        <b-col>
        <!-- <b-button :disabled="lockButtons" @click="downloadExcel('xlsx')" size="sm mr-3" variant="default" class="ml-2 mb-2">Descargar Excel</b-button>
        <b-button :disabled="lockButtons" @click="downloadExcel('pdf')" size="sm mr-3" variant="default" class="ml-2 mb-2">Descargar PDF</b-button> -->
        <b-button :disabled="lockButtons" v-show="actualGroup.state == 'draft'" @click="completeGroup" size="sm mr-3" variant="success" class="ml-2 mb-2">
            Completar
        </b-button>
        <b-button :disabled="lockButtons" v-show="actualGroup.state == 'completed'" @click="validateGroup" size="sm mr-3" variant="success" class="ml-2 mb-2">
            Validar
        </b-button>
        </b-col>
        <b-col>
        <progress-steps :steps="processSteps" :currentStep="actualGroup.state"></progress-steps>
        </b-col>
    </b-row>
    <b-card class="my-2 p-1">
        <b-row>
        <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
            <tbody>
                <tr>
                <td><b>Nombre:</b></td>
                <td>{{ actualGroup.name }}</td>
                </tr>
                <tr>
                <td><b>Ubicación:</b></td>
                <td>{{ actualGroup.location ? actualGroup.location.complete_name : '' }}</td>
                </tr>
                <tr>
                <td><b>Status:</b></td>
                <td><b-badge :variant="statusBadge(actualGroup.state).variant">{{
                    statusBadge(actualGroup.state).text }}</b-badge></td>
                </tr>
                <tr>
                <td><b>Contado Por:</b></td>
                <td>{{ actualGroup.counted_by_user ? actualGroup.counted_by_user.name : '' }}</td>
                </tr>
            </tbody>
            </table>
        </b-col>
        <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
            <tbody>
                <tr>
                <td><b>Fecha:</b></td>
                <td>{{ actualGroup.count_date }}</td>
                </tr>
                <tr>
                <td><b>Validado Por:</b></td>
                <td>{{ actualGroup.validated_by_user ? actualGroup.validated_by_user.name : '' }}</td>
                </tr>
                <tr>
                <td><b>Notas:</b></td>
                <td>{{ actualGroup.notes }}</td>
                </tr>
            </tbody>
            </table>
        </b-col>
        </b-row>
        <b-row>
        <b-col>
            <table class="table table-sm text-left table-responsive">
            <thead>
                <tr>
                <th>ID</th>
                <th>Producto</th>
                <th>Cantidad Sistema</th>
                <th>Cantidad Contada</th>
                <th>Diferencia</th>
                <th>Notas</th>
                <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" v-bind:key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.supply ? item.supply.name : '' }}</td>
                <td>{{ item.system_quantity }}</td>
                <td>{{ item.counted_quantity }}</td>
                <td>{{ item.difference }}</td>
                <td>{{ item.notes }}</td>
                <td>
                    <b-icon-pencil-fill @click="getItem(item.id)" variant="success" class="mx-2"></b-icon-pencil-fill>
                    <b-icon-trash-fill @click="deleteItem(item.id)" variant="danger" class="mx-2"></b-icon-trash-fill>
                </td>
                </tr>
            </tbody>
            </table>
        </b-col>
        </b-row>
        <b-row class="text-left my-2">
        <b-col>
            <b-button @click="emptyNewItem();$bvModal.show('modal-newProduct')" size="sm mr-3">Agregar Producto</b-button>
        </b-col>
        </b-row>
    </b-card>
    </b-container>

    <!-- Modal for new/edit item -->
    <b-modal id="modal-newProduct"  @close="emptyNewItem" :hide-footer="true" size="xl"
    title="Agregar Producto">
    <b-row>
        <b-col lg="12" cols="12">
        <b-form-group label="Producto">
            <vu-select  ref="supplySelect" v-model.number="newItem.supply_id"
            :options="supplies" :reduce="(x) => x.id" label="name"></vu-select>
        </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
        <b-form-group label="Cantidad Sistema">
            <b-form-input v-model.number="newItem.system_quantity" type="number" readonly></b-form-input>
        </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
        <b-form-group label="Cantidad Contada">
            <b-form-input ref="countedQty" v-model.number="newItem.counted_quantity" type="number"></b-form-input>
        </b-form-group>
        </b-col>
        <b-col lg="12" cols="12">
        <b-form-group label="Notas">
            <b-form-input v-model="newItem.notes"></b-form-input>
        </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col class="my-3">
        <b-button class="mr-3" @click="createItem" variant="success">Guardar</b-button>
        <b-button class="mr-3" @click="$bvModal.hide('modal-newProduct')" variant="danger">Cancelar</b-button>
        </b-col>
    </b-row>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ProgressSteps from "../components/ProgressSteps.vue";

export default {
name: "AjusteInventario",
components: {
    Nav,
    ProgressSteps
},
data() {
    return {
    lockButtons: false,
    processSteps: [
        { name: "draft", label: "Borrador" },
        { name: "completed", label: "Completado" },
        { name: "assigned", label: "Validado" }
    ],
    supplies: [],
    items: [],
    selectedSupply: {},
    actualGroup: {
        id: null,
        name: "",
        state: "draft",
        count_date: null,
        location_id: null,
        location: null,
        counted_by: null,
        counted_by_user: null,
        validated_by: null,
        validated_by_user: null,
        notes: ""
    },
    newItem: {
        id: null,
        supply_id: null,
        location_id: null,
        count_group_id: null,
        counted_quantity: 0,
        system_quantity: 0,
        difference: 0,
        notes: ""
    }
    };
},
mounted() {
    if (this.$route.params.id > 0) {
        this.actualGroup.id = this.$route.params.id;
        this.getGroup();
        this.getItems();
        this.getSupplies();
    }
},
methods: {
    statusBadge(state) {
    const badges = {
        draft: { variant: "warning", text: "Borrador" },
        completed: { variant: "primary", text: "Completado" },
        assigned: { variant: "success", text: "Validado" }
    };
    return badges[state] || { variant: "", text: state };
    },
    getItem(id){
        axios.get(`/stock/physicalcount/group/${this.actualGroup.id}/item/`+id)
        .then(res => {
            console.log(res.data);
            this.newItem = res.data;
            this.$bvModal.show('modal-newProduct')
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));

        
    },
    getGroup() {
    axios.get(`/stock/physicalcount/group/${this.actualGroup.id}`)
        .then(res => {
        this.actualGroup = res.data;
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    getItems() {
    axios.get(`/stock/physicalcount/group/${this.actualGroup.id}/items`)
        .then(res => {
        this.items = res.data;
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    getSupplies() {
    axios.get("/supplies")
        .then(res => {
        this.supplies = res.data.map(el => ({
            ...el,
            name: `${el.code} - ${el.name}`
        }));
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    createItem() {
    const isNew = !this.newItem.id;
    const method = isNew ? 'post' : 'put';
    const url = isNew 
        ? `/stock/physicalcount/group/${this.actualGroup.id}/item`
        : `/stock/physicalcount/group/${this.actualGroup.id}/item/${this.newItem.id}`;

    axios[method](url, this.newItem)
        .then(() => {
        this.getItems();
        this.$bvModal.hide('modal-newProduct');
        this.emptyNewItem();
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    completeGroup() {
    axios.post(`/stock/physicalcount/group/${this.actualGroup.id}/complete`)
        .then(() => {
        this.getGroup();
        this.makeToast("success", "Éxito", "Conteo completado");
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    validateGroup() {
    axios.post(`/stock/physicalcount/group/${this.actualGroup.id}/validate`)
        .then(() => {
        this.getGroup();
        this.makeToast("success", "Éxito", "Conteo validado");
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    deleteItem(id) {
    axios.delete(`/stock/physicalcount/group/${this.actualGroup.id}/item/${id}`)
        .then(() => {
        this.getItems();
        this.makeToast("success", "Éxito", "Item eliminado");
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    emptyNewItem() {
    this.newItem = {
        id: null,
        supply_id: null,
        location_id: this.actualGroup.location_id,
        count_group_id: this.actualGroup.id,
        counted_quantity: 0,
        system_quantity: 0,
        difference: 0,
        notes: ""
    };
    },

    makeToast(variant, title, text) {
    this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        appendToast: true
    });
    }
}
};
</script>