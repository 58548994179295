export const setupPermissionGuard = (router, store) => {
    router.beforeEach(async (to, from, next) => {
      // Check if user is authenticated
      const token = localStorage.getItem('user-token');
      if (token) {
        // Get current actions from store
        const currentActions = store.getters['auth/getActions'];
        // If no actions are present, fetch them
        if (!currentActions || currentActions.length === 0) {
          try {
            await store.dispatch('auth/getActions');
          } catch (error) {
            console.error('Error fetching permissions:', error);
          }
        }
      }
      next();
    });
  };