// src/mixins/permissionMixin.js
import { mapGetters } from 'vuex';

export const permissionMixin = {
    created() {

    },
    computed: {
        ...mapGetters('auth', ['hasPermission']),
      },
};
