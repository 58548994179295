import axios from 'axios'
const state = {
    actions: [],
    server: {
        tenant: localStorage.getItem('tenant') || ''
    },
};

const getters = {
    tenant: (state) => state.server.tenant,
    getActions: (state) => state.actions,
    hasPermission: state => rrn => state.actions.includes(rrn)
};

const actions = {
    changeTenantData({ commit }, tenant) {
        localStorage.setItem('tenant', tenant)
        if (process.env.NODE_ENV === 'production') {
            axios.defaults.baseURL = 'https://pos.tizon.pereztecnologia.com/' + localStorage.getItem('tenant')
        } else {
            axios.defaults.baseURL = process.env.VUE_APP_SERVER_DOMAIN + localStorage.getItem('tenant')
        }
        commit('CH_TENANT_DATA', tenant)
    },
    getActions({ commit }) {
        console.log("getactions")
        axios.get('/permissions/mine')
            .then(resp => {
                commit('SET_ACTIONS', resp.data)
            }).catch(err => {
                console.log(err)
            })
    },
    setActions({ commit }, actions) {
        commit('SET_ACTIONS', actions)
    },
    hasPermission({ state }, permission) {
        console.log("haspermission")
        return state.actions.find(action => action.rrn == permission)
    }
};

const mutations = {
    CH_TENANT_DATA(state, tenant) {
        state.server.tenant = tenant
    },
    SET_ACTIONS(state, actions) {
        state.actions = actions
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};